import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";

import { PostContainer } from "components/Post/styles";
import { Row, Col } from "react-grid-system";
// import { fetchUserData, updateProjectRef } from "api/db"
import {
  Header as OnboardingHeader,
  GoBack
} from "components/Onboarding/OnboardingComponents";

import {
  Card,
  Title,
  Left,
  Right,
  Middle,
  Img
} from "components/Elements/Card";

const Spacer = styled.div`
  width: 100%;
  height: 50px;
`;

const Exit = styled.span`
  position: absolute;
  right: 48px;
  top: 24px;
  cursor: pointer;
`;

const Button = styled.button`
  padding: 8px 15px;
  font-size: 24px;
  cursor: pointer;
  background: #fff;
  color: #000;
  border: 4px solid #0394fc;
  margin-bottom: 30px;
  border-radius: 4px;
  transition: all 300ms ease;
  font-weight: bold;
  &:hover {
    opacity: 0.8;
    background: #0394fc;
    color: #fff;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

function Project(props) {
  return (
    <Link to={props.url}>
      <Card className="project text-center">
        <Img src={props.img} />
        <h1 className="text-2xl leading-snug font-bold text-gray-800 mt-6">
          {props.title}
        </h1>
        <Left className="text-xs tracking-tight rounded-lg py-2 px-4 bg-gray-200 text-gray-800 mr-1 font-bold">
          {" "}
          {props.language}
        </Left>
        <Right className="uppercase text-xs tracking-wide rounded-lg py-2 px-4 bg-gray-200 text-gray-800 mr-1 font-bold">
          {" "}
          {props.difficulty}
        </Right>
      </Card>
    </Link>
  );
}

class OnboardingRecs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextThree: []
    };
  }

  componentDidMount() {
    let nextThree = [];
    let i = 0;
    let filteredTutorials = [];
    console.log(this.props.data.allTutorial);
    this.props.data.allTutorial.edges.map((tutorial, index) => {
      // leaving this here in case we need to filter out any results
      filteredTutorials.push(tutorial);
    });
    let length = filteredTutorials.length;
    let chosen = [];
    while (i < length && i < 3) {
      let num = parseInt(Math.random() * length);
      if (!chosen.includes(num)) {
        chosen.push(num);
        nextThree.push(filteredTutorials[num]);
        i++;
      }
    }
    this.setState({
      nextThree: nextThree
    });
  }

  // exitClick(e) {
  //   if (process.env.NODE_ENV === "production") {
  //     typeof window !== "undefined" &&
  //       window.gtag("event", "click", {
  //         event_label: "onboarding recommendations",
  //         event_category: "Onboarding Exit"
  //       });
  //   }
  // }

  render() {
    const post = this.props.data.allTutorial;

    return (
      <>
        <OnboardingHeader />
        <div className="max-w-screen-xl mx-auto">
          <Helmet title={`Project Reccomendations | Enlight`} />

          <Link
            style={{ color: "black" }}
            to="/home"
            //onClick={e => this.exitClick(e)}
          >
            <Exit id="exit">&#10005;</Exit>
          </Link>

          <h1 className="text-2xl font-bold" style={{ textAlign: "center" }}>
            Here are our recommended projects for you
          </h1>
          {typeof window != "undefined" &&
          localStorage.getItem("previousProjectId") != "undefined" ? (
            <GoBack
              paintDrip
              to={`/projects/${localStorage.getItem("previousProjectId")}`}
              hex="#438cee"
              duration={1}
            >
              No thanks, take me back to{" "}
              <span>{localStorage.getItem("previousProjectTitle")}</span>{" "}
              &#8250;
            </GoBack>
          ) : null}
          <Row>
            {this.state.nextThree.map((tutorial, index) => (
              <Col
                offset={{
                  lg:
                    this.state.nextThree.length > 2 && index == 0
                      ? 0
                      : this.state.nextThree.length > 1 && index == 0
                      ? 2
                      : index == 0
                      ? 4
                      : 0
                }}
                lg={4}
                sm={6}
                key={index}
              >
                <Project
                  url={`/projects/${tutorial.node.id}`}
                  id={tutorial.node.id}
                  img={tutorial.node.img}
                  title={tutorial.node.title}
                  author={tutorial.node.author}
                  difficulty={tutorial.node.difficulty}
                  language={tutorial.node.language}
                />
              </Col>
            ))}
          </Row>

          <ButtonContainer>
            <a href="https://discord.gg/8FwTpfN" target="_blank">
              <Button>Join the Enlight Community</Button>
            </a>
          </ButtonContainer>
        </div>
      </>
    );
  }
}

export default OnboardingRecs;

export const onboardingRecsQuery = graphql`
  query OnboardingRecsQuery($cat: String!, $diff: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allTutorial(
      filter: {
        published: { eq: true }
        category: { eq: $cat }
        difficulty: { eq: $diff }
      }
    ) {
      edges {
        node {
          id
          img
          date
          category
          title
          language
          difficulty
        }
      }
    }
  }
`;
